.projects__grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  grid-gap: 1em;
  margin-top: 0em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 4em;
  text-transform: uppercase;
  font-size: 3em;
  color: white;
}

.project-description {
  font-size: 1em;
}