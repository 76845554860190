.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section.contact {
  text-align: center;
  padding: 1em 0;
  background-color: var(--clr-footer-bg);
}

.contact__icons {
  display: flex;
  justify-content: center;
  gap: 1.5em;
  margin-top: 1em;
}

.link--icon {
  color: var(--clr-primary);
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.link--icon:hover {
  color: var(--clr-primary-dark);
}

.footer {
  font-size: 0.8rem;
  color: var(--clr-text-secondary);
  margin-top: 1em;
}