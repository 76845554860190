#skills {
  padding-top: 7.5em;
}

.skills__section {
  padding: 20px;
  text-align: center;
}

.about__title {
  color: white;
  text-transform: uppercase;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.about__intro {
  color: white;
  font-size: 1.0em;
  margin-bottom: 40px;
  font-weight: lighter;
}

/* Line after the first paragraph */
.about__line {
  border: none;
  height: 1px;
  background-color: #fff;
  margin: 20px 0 40px;
}

.about__intern {
  color: #eee;
  font-size: 1.0em;
  margin-bottom: 40px;
}

.job-experience__title {
  color: white;
  font-size: 1.9em;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin: 40px 0;
  padding-bottom: 40px;
  border-bottom: 2px solid #fff;
}

.timeline__event {
  position: relative;
  width: 20%;
  text-align: center;
}

.timeline__icon {
  width: 60px;
  height: 60px;
  background-color: #2c3e50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto 10px;
}

.timeline__icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline__title {
  font-size: 1.0rem;
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
}

.timeline__date {
  font-size: 0.8rem;
  color: #B7DEF3;
  margin-bottom: 5px;
}

.timeline__description {
  font-size: 0.8rem;
  color: rgb(214, 214, 214);
}

.timeline::before {
  content: '';
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
}

.about__closing {
  color: #eee;
  font-size: 1.2em;
  margin-top: 40px;
  margin-bottom: 40px;
}

.skills__title {
  color: white;
  text-transform: uppercase;
  font-size: 1.9em;
  margin-bottom: 20px;
}

.skills__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.skills__list-item {
  background-color: white;
  color: black;
  border: 1px solid #B7DEF3;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 5px;
  box-shadow: 0 4px 8px rgb(81, 80, 80);
}

.skills__icon {
  margin-right: 10px;
}