.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  font-size: 0.9rem;
  background-color: #d5e4ed;
  border-radius: 0.7em;
}

.project__name{
  color:#131C3E;
}

.project:hover {
  transform: translateY(-3px);
}

.project__description {
  margin-top: 1em;
  font-size: 0.8rem;
  color: #131C3E;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  /* color: var(--clr-fg-alt); */
  color: #131C3E;
}

.project .link--icon {
  margin-left: 0.5em;
  color: #131C3E;

}

.project__image {
  width: 90%;
  height: 150px;
  object-fit: cover;
  border-radius: 0.7em;
  margin-top: 1em;
  border: 1px solid #131C3E;
}