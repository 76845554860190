.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  position: sticky;
  top: 0;
  z-index: 999;
  color: #fff;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav__list {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  margin-left: auto;
}

.nav__list-item {
  margin-left: 2em;
}

.link--nav {
  color: #fff;
  text-decoration: none;
  padding: 0.5em 1em;
  display: inline-block;
  transition: background-color 0.3s ease;
  vertical-align: middle;
}

.nav__button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0.5em 1em;
  display: inline-block;
  vertical-align: middle;
}

.link--nav:hover {
  border-radius: 5px;
}

.nav__hamburger {
  background: none;
  border: none;
  font-size: 2em;
  color: #fff;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .nav__list {
    display: none;
    flex-direction: column;
    position: fixed;
    inset: 0;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }

  .nav__list.show {
    display: flex;
  }

  .nav__list-item {
    margin: 1.5em 0;
  }

  .nav__hamburger {
    display: block;
    margin-left: auto;
  }
}