#about {
   padding-top: 10em;
 }

 .about {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   justify-content: flex-start;
   margin-top: 8.9em;
   padding: 0;
   width: 100%;
   height: auto;
 }

 .about__text {
   flex: 1;
   margin-right: 2em;
   margin-top: 0;
   padding-top: 1em;
 }

 .about__name {
   color: var(--clr-primary);
   font-size: 1em;
 }

 .about__school {
   color: #bcbcbc;
   font-weight: normal;
   font-size: 0.9em;
 }

 .about__role {
   margin-top: 1em;
   font-size: 2em;
 }

 .about__desc {
   font-size: 1rem;
   max-width: 600px;
 }

 .about__desc,
 .about__contact {
   margin-top: 2.4em;
 }

 .about__contact {
   margin-top: 1em;
   display: flex;
   justify-content: flex-start;
   align-items: center;
 }

 .about__contact .link--icon {
   margin-right: 1em;
   display: flex;
 }

 .about__image {
   display: inline-block;
   border-radius: 50%;
   border: 8px solid #b7def3;
   flex-shrink: 0;
   max-width: 350px;
   margin-right: 0.5em;
   margin-top: -3em;
 }

 .about__image img {
   display: block;
   width: 100%;
   height: auto;
   border-radius: 50%;
   object-fit: cover;
 }

 .modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
 }

 .modal-content {
   background: white;
   padding: 0;
   border-radius: 0;
   position: relative;
   width: 100%;
   height: 100%;
   overflow: hidden;
 }

 .close {
   position: absolute;
   top: 20px;
   right: 20px;
   font-size: 40px;
   color: #000;
   cursor: pointer;
   z-index: 1100;
 }

 .resume-iframe {
   width: 100%;
   height: 100%;
   border: none;
 }

 .modal-footer {
   position: absolute;
   bottom: 20px;
   width: 100%;
   text-align: center;
   padding: 10px;
   background: rgba(255, 255, 255, 0.8);
 }

 .btn--download {
   background-color: #007bff;
   color: white;
   padding: 10px 20px;
   text-decoration: none;
   border-radius: 4px;
   display: inline-block;
 }

 .btn--download:hover {
   background-color: #0056b3;
 }

 @media (max-width: 768px) {
   .about {
     flex-direction: column;
     align-items: center;
     margin-top: 3em;
   }

   .about__text {
     text-align: center;
     margin-right: 0;
   }

   .about__contact {
     justify-content: center;
     margin-top: 2em;
   }

   .about__image {
     margin-top: 2em;
     margin-right: 0;
   }
 } 



 